import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionWithVines from '../components/sections/SectionWithVines/SectionWithVines';
import SectionGrid from '../components/sections/SectionGrid/SectionGrid';
import SectionContent from '../components/sections/SectionContent/SectionContent';
import SectionDecorative from '../components/sections/SectionDecorative/SectionDecorative';
import SectionAlternateGrid from '../components/sections/SectionAlternateGrid/SectionAlternateGrid';
import StoryContainer from '../components/Story/StoryContainer/StoryContainer';
import SectionBreadcrumb from '../components/sections/SectionBreadcrumb/SectionBreadcrumb';

const Story = ({
    pageContext,
    location,
    data: {
        contentfulStlPageStory: {
            slug,
            seoTitle,
            seoDescription,
            sections,
            tiles,
            tileSections,
            pageTitle
        }
    }
}) => {

    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
            />
            <div className='content'>
                {
                    sections.map((section: any, index: number) => {

                        switch (section.__typename) {
                            case "ContentfulComponentBackgroundHero":
                                return (
                                    <HeroComponent data={section} key={index} />
                                )
                            case "ContentfulSectionWithVines":
                                return (
                                    <SectionWithVines data={section} key={index} />
                                )
                            case "ContentfulSectionGrid":
                                return (
                                    <SectionGrid data={section} key={index} />
                                )
                            case "ContentfulSectionContent":
                                return (
                                    <SectionContent data={section} key={index} />
                                )
                            case "ContentfulStlSectionDecorativeItems":
                                return (
                                    <SectionDecorative data={section} key={index} />
                                )
                            case "ContentfulSectionAlternateGrid":
                                return (
                                    <SectionAlternateGrid data={section} key={index} />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadcrumb data={section} key={index} title={pageTitle} />
                                )
                        }
                    })
                }
                <div>
                    <StoryContainer tiles={tiles} content={tileSections} />
                </div>
            </div>
        </div>
    )
}

export const StoryQuery = graphql`
    query storyQuery ($id: String!){
        contentfulStlPageStory(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            pageTitle
            sections {
                __typename
                ...componentBackgroundHero
                ...sectionWithVines
                ...sectionGrid
                ...sectionDecorativeItems
                ...sectionContent
                ...sectionAlternateGrid
                ...sectionBreadcrumb
            }
            tiles {
                title
                image {
                    gatsbyImageData(quality: 75, formats: WEBP)
                }
            }
            tileSections {
                ...stlAdditional
            }
        }
    }

`

export default Story