import React, { useState } from 'react'
import styled from 'styled-components'
import { desktopBp } from '../../../styles'
import StoryContent from '../StoryContent/StoryContent'
import StoryOptions from '../StoryOptions/StoryOptions'

const StoryContainer = ({
    tiles,
    content
    // restData
}) => {
    const [option, setOption] = useState(0)
    return (
        <Container className='container-fs'>
            <Options>
                <StoryOptions tiles={tiles} setter={setOption} value={option} />
            </Options>
            <Content>
                <StoryContent data={content[option]?.sections || []} />
            </Content>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 45px;
    position: relative;
`

const Options = styled.div`
    position: sticky;
    top: 0;
    ${desktopBp}{
        top: 54px;
    }
    padding-top: 15px;
    padding-bottom:15px;
    margin-bottom: 45px;
    z-index: 2;
    background-color: #000D0D;

`

const Content = styled.div`
`

export default StoryContainer