import React from 'react'

import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import { alignment, colors, desktopBp, fonts, mobileBp } from '../../../styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

const StoryGrid = ({
    data
}) => {
    const {
        title,
        titleAlignment,
        numberOfGridItems,
        gridItems,
        columnGap,
        containerSize
    } = data


    return (
        <div className={`container container-${containerSize || 'lg'} `}>
            <Wrapper>
                <Title alignment={titleAlignment}>{title}</Title>
                <Card_Container
                    numberOfGrids={numberOfGridItems}
                    columnGap={columnGap}
                >
                    {
                        gridItems.map((item: any, index: number) => {
                            const { __typename } = item
                            switch (__typename) {
                                case "ContentfulComponentItem":
                                    return (
                                        <ItemCard key={index}>
                                            <ItemImg
                                                image={item.image.gatsbyImageData}
                                                alt={item.title}
                                            />
                                            <Item_Title>{item.title}</Item_Title>
                                            <Item_Desc>{item.description?.description}</Item_Desc>
                                        </ItemCard>
                                    )
                            }
                        })
                    }
                </Card_Container>
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
    margin-top: 80px;
`

const Title = styled.h2`
    color: ${colors.gold};
    font-size: 22px;
    margin-bottom: 40px;
    text-align: ${(props) => alignment[props.alignment]};
    font-family: ${fonts.bold};
    font-weight: bold;
`

const Card_Container = styled.div`
    /* display: flex;
    justify-content: space-evenly; */
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.numberOfGrids || 3}, auto)`};
    column-gap: ${(props) => `${`${props.columnGap}px` || '20px'}`};
    ${desktopBp}{
        grid-template-columns: repeat(1, 1fr);
        row-gap: 50px;
    }
`

const ItemImg = styled(GatsbyImage)`
    width: 50%;
    margin-bottom: 10px;
    width: 104px;
`

const ItemCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

`

const Item_Title = styled.p`
    line-height: 30px;
    font-size: 22px;
    font-family: ${fonts.bold};
    color: ${colors.gold};
    font-weight: 500;
    letter-spacing: -3px;
    margin-bottom: 20px;
    text-align: center;
`

const Item_Desc = styled.p`
    line-height: 23px;
    font-family: ${fonts.regular};
    font-size: 14px;
    text-align: center;
`

const Card_Title = styled.span`
    font-size: 1.375rem;
    color: ${colors.gold};
    text-align: center;
    margin-top: 43px;
    font-family: ${fonts.bold};
    z-index: 1;
    font-weight: 500;

`
export default StoryGrid