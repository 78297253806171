import React from 'react'
import styled from 'styled-components';
import { colors, desktopBp, fonts, mobileBp } from '../../../styles';

import { GatsbyImage } from 'gatsby-plugin-image';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

const StoryOptions = ({
    tiles,
    setter,
    value
}) => {
    return (
        <Container className='container container-lg'>
            <Grid>
                {
                    tiles.map(({ title, image }, index) => (
                        <div key={index}>
                            <Radio_Tile
                                type="radio"
                                name="tiles"
                                id={index}
                                defaultChecked={index === 0}
                                hidden
                                onChange={() => setter(index)}
                            />
                            <Tile htmlFor={index} className="tile_card">
                                <Image
                                    image={image.gatsbyImageData}
                                    className="tile_image"
                                    alt={title || image.title}
                                    objectFit="contain"
                                />
                                <Tile_Title>{title}</Tile_Title>
                            </Tile>
                        </div>
                    ))
                }
            </Grid>
            <Swiper_Style
                // spaceBetween={35}
                slidesPerView={2.24}
                onSlideChange={(e) => setter(e.activeIndex)}
            >
                {
                    tiles.map(({ title, image }, index) => (
                        <SwiperSlide key={index}
                            onClick={() => setter(index)}
                        >
                            <Radio_Tile
                                type="radio"
                                name="swiper_tiles"
                                id={`mobile-${index}`}
                                defaultChecked={index === 0}
                                checked={index === value}
                                hidden
                            />
                            <Tile htmlFor={`mobile-${index}`} className="tile_card">
                                <Image
                                    image={image.gatsbyImageData}
                                    className="tile_image"
                                    alt={title || image.title}
                                    objectFit="contain"
                                />
                                <Tile_Title>{title}</Tile_Title>
                            </Tile>
                        </SwiperSlide>
                    ))
                }
            </Swiper_Style>
        </Container>
    )
}

const Container = styled.div`
    padding: 0 15px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 90px;
    /* position: sticky;
    top: 0; */
    ${desktopBp}{
        display: none;
    }
`

const Radio_Tile = styled.input`
    & ~ .tile_card {
        .tile_image {
            position: relative;
            opacity: 0.5;
            transition: opacity 0.1s ease-in-out;
        }
    }
    &:checked ~ .tile_card {
        .tile_image {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
        }
    }

`

const Swiper_Style = styled(Swiper)`
    ${mobileBp}{
        display: none;
    }
`

const Tile = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`

const Image = styled(GatsbyImage)`
    margin-bottom: 20px;
    max-height: 120px;
`

const Tile_Title = styled.p`
    font-size: 1rem;
    font-family: ${fonts.bold};
    font-weight: 500;
    color: ${colors.gold};
`

export default StoryOptions