import React from 'react'
import styled from 'styled-components'

import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingLevel from '../../shared/HeadingLevel/HeadingLevel'
import { RichText } from '../RichText/RichText'
import { desktopBp, mobileBp } from '../../../styles'

const SectionAlternateGrid = ({
    data,
    staticWidth = null,
    table_Right_alignment = false,
    image_alignment_top = false
}) => {
    const {
        screenSize,
        headingLevel,
        title,
        desktopImage,
        content,
        alignment
    } = data

    return (
        <Container className={`container container-${staticWidth || screenSize}`}>
            <Wrapper image_alignment_top={image_alignment_top}>
                <Content className={`${table_Right_alignment ? 'table-right' : ''}`} alignment={alignment}>
                    <Title>
                        <HeadingLevel title={title} headingLevel={headingLevel} />
                    </Title>
                    <Article>
                        <RichText content={content} />
                    </Article>
                </Content>
                <Image
                    image={desktopImage.gatsbyImageData}
                    alt={desktopImage.title}
                    objectFit="contain"
                    alignment={alignment}
                />
            </Wrapper>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 60px !important;
`

const Wrapper = styled.div`
    display: flex;
    column-gap: 40px;
    align-items: ${(props) => props.image_alignment_top ? 'flex-start;' : 'center'};
    ${desktopBp}{
        flex-direction: column;
    }
    .table-right {
        table {
            tr {
                td:nth-child(2){
                    text-align: right;
                }
            }
        }
    }
`

const Content = styled.div`
    width: 100%;
    ${mobileBp}{
        width: 50%;
        order: ${(props) => props.alignment === 'Left' ? 1 : 0};
    }
    order: 1;
`

const Title = styled.div`
    margin-bottom: 20px;
`

const Article = styled.div`
    .rich-text-p{
        margin-bottom: 25px;
    }
`

const Image = styled(GatsbyImage)`
    ${mobileBp}{
        width: 50%;
        order: ${(props) => props.alignment === 'Left' ? 0 : 1};
    }
    order: 0;
`
export default SectionAlternateGrid