import React from 'react'
import SectionAlternateGrid from '../../sections/SectionAlternateGrid/SectionAlternateGrid'
import SectionGrid from '../../sections/SectionGrid/SectionGrid';
import StoryGrid from '../StoryGrid/StoryGrid';

const StoryContent = ({
    data
}) => {

    return (
        <div>
            {
                data.map((section, index) => {
                    switch (section.__typename) {
                        case "ContentfulSectionAlternateGrid":
                            return (
                                <SectionAlternateGrid data={section} key={index} />
                            )
                        case "ContentfulSectionGrid":
                            return (
                                <StoryGrid data={section} key={index} />
                            )
                    }
                })
            }
        </div>
    )
}

export default StoryContent